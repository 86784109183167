<template>
<!--  <basic-layout>-->
<!--  </basic-layout>-->
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App',
  mounted() {
    const apiUrl = process.env.VUE_APP_API_BASE_URL;

    //FIXME:remove when noticed, no impact but helped local dev speed a lot
    if (apiUrl.includes('localhost')) {
      return;
    }

    // Load OneTrust cookies consent notice
    const script1 = document.createElement('script');
    script1.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
    script1.setAttribute('type', 'text/javascript');
    script1.setAttribute('charset', 'UTF-8');


    // Check the current environment based on the apiUrl
    if (apiUrl.includes('sandbox')) {
      // If the apiUrl includes 'sandbox', it means we are in the sandbox environment
      // Set the 'data-domain-script' attribute to the specific value for the sandbox environment
      script1.setAttribute('data-domain-script', '018fa6e2-9083-7e87-9dca-faf15bae572c');
    } else if (apiUrl.includes('qa')) {
      // If the apiUrl includes 'qa', it means we are in the QA environment
      // Set the 'data-domain-script' attribute to the specific value for the QA environment
      script1.setAttribute('data-domain-script', '018fa72c-2abb-7832-ae49-9f0312d4ee3c');
    } else {
      // If the apiUrl does not include 'sandbox' or 'qa', it means we are in the production environment, ignore develop for now.
      script1.setAttribute('data-domain-script', '0193e4a5-de81-762f-a263-3657fffd5ea7');
    }


    document.body.appendChild(script1);

    const script2 = document.createElement('script');
    script2.type = 'text/javascript';
    script2.text = `
      function OptanonWrapper() {
        //
      }
    `;
    document.body.appendChild(script2);
  }
};
</script>

<style scoped>
</style>
